import { Injectable } from '@angular/core';
import { format, parse } from 'date-fns';
import { ISection } from './types';
import { ISchedule } from '../../types/ISchedule';
import { ITag } from '../../types/ITag';
import { LoggerService } from '../logger.service';
import { IConditions } from '../../types/IConditions';
import { isEmpty } from 'ramda';
import { TranslateService } from '@ngx-translate/core';

type TSectionKey = 'Spot' | 'Event';

@Injectable({
  providedIn: 'root',
})
export class SpotEventsService {
  dateFormat = 'YYYY-MM-DD';

  constructor(private logger: LoggerService, public translate: TranslateService) {}

  compareSectionSchedules(
    schedules: ISchedule[],
    sectionKey: TSectionKey,
    tagType
  ): Array<ISection> {
    const out: Array<ISection> = [];

    const comparedSchedules = schedules.reduce((acc, s, i) => {
      const sessionTags = s.Tags.filter(tag => tag.type === tagType);
      if (sessionTags && sessionTags.length) {
        sessionTags.sort((a, b) =>
          a.name[this.translate.currentLang] > b.name[this.translate.currentLang] ? 1 : -1
        );
        const sectionId = sessionTags.reduce((_acc, tag, _i) => {
          _acc.length > 0 ? (_acc += '_' + tag.uuid) : (_acc += tag.uuid);
          return _acc;
        }, '');
        // this.sessionTags[sectionId] = sessionTags;
        const existed = acc.find(_s => _s.uuid === sectionId);
        if (existed) {
          existed.items.push(s);
          return acc;
        }
        acc.push({
          uuid: sectionId,
          tags: sessionTags,
          items: [s],
        });
        return acc;
      }
      return acc;
    }, []);
    console.log('first iteration', comparedSchedules, out);
    comparedSchedules.map((s, i) => {
      s.items.sort((a, b) => parse(a.begin).getTime() - parse(b.begin).getTime());
      s.items.map(item => {
        const existed = out.find(sect => sect.section.uuid === item[sectionKey].uuid);
        if (!existed) {
          // const sectSchedules = [...comparedSchedules];
          const sectSchedules = comparedSchedules.map(a => ({ ...a, items: [...a.items] }));
          sectSchedules.map((_s, _i) => {
            _s.items = _s.items.filter(_item => _item[sectionKey].uuid === item[sectionKey].uuid);
          });
          out.push({
            section: item[sectionKey],
            schedules: sectSchedules,
          });
        }
      });
    });
    console.log(out);
    return out;
  }

  compareCalendar(eventsCalendar) {
    const { dates } = eventsCalendar;

    if (isEmpty(dates)) {
      return;
    }
    const calendarDays = [];
    const startDate = new Date();
    const endDate = parse(eventsCalendar.max);
    const step = 1;
    const endTime = endDate.getTime();
    const currentDate = startDate;
    currentDate.setHours(0, 0, 0, 0);

    while (currentDate.getTime() <= endTime) {
      const dateKey = format(currentDate, this.dateFormat);
      const _data = { dt: dateKey, exist: true };

      if (!dates[dateKey]) _data.exist = false;
      calendarDays.push(_data);

      currentDate.setDate(currentDate.getDate() + step);
    }

    return calendarDays;
  }

  getSchedulesTags(schedules: ISchedule[], type): ITag[] {
    return schedules
      .reduce((acc, s, i) => {
        return acc.concat(
          s.Tags.filter(tag => {
            return tag.type === type && !acc.some(_tag => _tag.uuid === tag.uuid);
          })
        );
      }, [])
      .sort((a, b) =>
        a.name[this.translate.currentLang] > b.name[this.translate.currentLang] ? 1 : -1
      );
  }

  search(sections: ISection[], conditions: IConditions): ISection[] {
    if (!conditions._lookup && (!conditions.tags || !conditions.tags.length)) {
      return sections;
    }
    conditions._lookup = conditions._lookup.replace(/\s/g, '').toLowerCase();
    let _sections = [...sections];
    if (conditions._lookup && conditions.tags && conditions.tags.length) {
      _sections = _sections.filter(section => {
        return Object.keys(section.section.name).some(lang =>
          section.section.name[lang]
            .replace(/\s/g, '')
            .toLowerCase()
            .match(conditions._lookup)
        );
      });
    }
    _sections = _sections.map((section: ISection) => {
      const _section: ISection = { ...section };
      _section.schedules = _section.schedules.map((schedule, index) => {
        const _schedule = { ...schedule };
        _schedule.items = [..._schedule.items].filter((item: ISchedule) => {
          return item.Tags.some(t => {
            return (
              (conditions.tags && conditions.tags.some(uuid => uuid === t.uuid)) ||
              (conditions._lookup &&
                (!conditions.tags || !conditions.tags.length) &&
                (Object.keys(t.name).some(lang => {
                  return t.name[lang]
                    .replace(/\s/g, '')
                    .toLowerCase()
                    .match(conditions._lookup);
                }) ||
                  Object.keys(section.section.name).some(lang =>
                    section.section.name[lang]
                      .replace(/\s/g, '')
                      .toLowerCase()
                      .match(conditions._lookup)
                  )))
            );
          });
        });
        return _schedule;
      });
      _section.schedules = _section.schedules.filter(s => s.items.length > 0);
      return _section;
    });
    return _sections;
  }
}
